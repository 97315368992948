import React, { FC } from 'react';
import clsx from 'clsx';
import WorkflowStatusBadge from 'app/components/workflowStatus/WorkflowStatusBadge';
import { Chip, Tooltip } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  statuses: { status: number; count: number }[];
  selectedStatuses: number[];
  onSelectPostStatus: (status: number) => void;
};

const StatusesFilter: FC<Props> = ({
  statuses,
  selectedStatuses,
  onSelectPostStatus,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-2">
        {statuses.map(({ status, count }) => (
          <div
            key={status}
            onClick={() => onSelectPostStatus(status)}
            className={clsx(
              'tw-flex tw-cursor-pointer tw-items-center tw-rounded  tw-px-2 hover:tw-bg-primary-10',
              {
                'tw-bg-primary-10': selectedStatuses.includes(status),
              },
            )}
          >
            <WorkflowStatusBadge
              status={status}
              variant="plain"
              className={clsx({
                'tw-text-grayscale-180': !selectedStatuses.includes(status),
              })}
            />
            <Tooltip content={t('timeSavers.countOfPostsWithStatus')}>
              <Chip
                className="tw-ml-auto"
                variant={selectedStatuses.includes(status) ? 'primary' : 'grey'}
              >
                {count}
              </Chip>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusesFilter;
