import React, { FC } from 'react';
import { ListPost } from 'types/PostDetail';
import HorizontalPostCard from 'app/modules/posts/components/HorizontalPostCard';
import clsx from 'clsx';
import { Alert, Checkbox } from '@kontentino/ui';

type Props = {
  posts: ListPost[];
  selectedPosts: ListPost[];
  disabledPosts?: number[];
  warningMessages?: Record<string, string>;
  onSelectPost: (post: ListPost) => void;
  className?: string;
};

const List: FC<Props> = ({
  posts,
  selectedPosts,
  disabledPosts,
  warningMessages,
  onSelectPost,
  className,
}) => {
  return (
    <div
      className={clsx(
        'tw-flex tw-h-[430px] tw-flex-col tw-gap-4 tw-overflow-auto tw-p-4',
        className,
      )}
      translate="no"
    >
      {posts.map((post) => (
        <div className="tw-relative" key={`post-${post.id}`}>
          <div className="tw-absolute tw-left-2 tw-top-2 tw-z-10">
            <Checkbox
              size="small"
              onClick={() => onSelectPost(post)}
              onChange={() => {}}
              checked={selectedPosts
                .map((selectedPost) => selectedPost.id)
                .includes(post.id)}
            />
          </div>
          <HorizontalPostCard
            post={post}
            withPageInfo
            onClick={() => onSelectPost(post)}
            className={clsx('tw-rounded tw-border-none tw-pl-9', {
              'tw-opacity-50': disabledPosts?.includes(post.id),
              '!tw-bg-grayscale-5': selectedPosts
                .map((selectedPost) => selectedPost.id)
                .includes(post.id),
            })}
          />

          {warningMessages?.[post.id] && (
            <Alert variant="warning" className="tw-mt-1">
              {warningMessages?.[post.id]}
            </Alert>
          )}
        </div>
      ))}
    </div>
  );
};

export default List;
